import $ from "jquery";
import jQueryBridget from 'jquery-bridget';
import Isotope from "isotope-layout";
require('isotope-packery');

jQueryBridget( 'isotope', Isotope, $ );

if($('.grid--persons').length) {
  //console.log('teststs');
  var $gridPersons = $('.grid--persons');

  $gridPersons.isotope({
    itemSelector: '.grid-item',
    // layoutMode: 'packery'
    masonry: {
      // columnWidth: 100,
      horizontalOrder: true
    }
  }) 

  $('.region-filter').on('click', function() {
    $('.region-filter').removeClass('active');
    $(this).addClass('active');
    var region = $(this).attr('data-region-filter');
    console.log(region);
    $gridPersons.isotope({ filter: '.' + region });
  });

  $( document ).ready(function() {
    $('[data-region-filter="head-office"]').click();
  });

  // $('#awards-award').on('change', function() {
  //   award = this.value;
  //   filterAwards($gridPersons, award, location, year);
  // });

  // $('#awards-location').on('change', function() {
  //   location = this.value;
  //   filterAwards($gridPersons, award, location, year);
  // });
  
  // $('#awards-year').change(function(){
  //   year = this.value;
  //   filterAwards($gridPersons, award, location, year);
  // });

  // $('.awards-reset').on('click', function(e) {
  //   e.preventDefault();
  //   resetAwards($gridPersons);
  // });
}

